import {put} from 'redux-saga/effects';
import {apiCall} from '../../../helpers/backend_helper';

export const getInfoSettings = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/settings/info'});
    } catch (error) {
        return false;
    }
}

export const setSettings = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/settings', data: data});
    } catch (error) {
        return false;
    }
}

export const setSettingsEmails = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/settings/emails', data: data});
    } catch (error) {
        return false;
    }
}

export const setSubscription = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/settings/subscription', data: data});
    } catch (error) {
        return false;
    }
}
export const setPremium = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/settings/premium', data: data});
    } catch (error) {
        return false;
    }
}

export const setServiceFee = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/settings/servicefee', data: data});
    } catch (error) {
        return false;
    }
}

export const setSendSMSFee = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/settings/sendsmsfee', data: data});
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}
