import React, {Component} from "react";
import {Card, CardBody, Container, Button} from "reactstrap";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getInfoSettings,
    setPremium, setSendSMSFee,
    setServiceFee,
    setSettings,
    setSettingsEmails,
    setSubscription
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';

class SettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: null
        };
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = await setSettings(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Impostazioni salvate con successo", "success");
            this.setState({settings: formResult.settings});
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con il salvataggio: " + formResult.error, "danger");

        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleValidSubmitServiceFee(event, values, edit = false) {
        console.log(values)
        let formResult = await setServiceFee(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Impostazioni salvate con successo", "success");
            this.setState({settings: formResult.settings});
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con il salvataggio: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleValidSubmitSendSMSFee(event, values, edit = false) {
        console.log(values)
        let formResult = await setSendSMSFee(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Impostazioni salvate con successo", "success");
            this.setState({settings: formResult.settings});
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con il salvataggio: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleValidSubmitSubscription(event, values, edit = false) {
        console.log(values)
        let formResult = await setSubscription(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Impostazioni salvate con successo", "success");
            this.setState({settings: formResult.settings});
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con il salvataggio: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleValidSubmitPremium(event, values, edit = false) {
        console.log(values)
        let formResult = await setPremium(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Impostazioni salvate con successo", "success");
            this.setState({settings: formResult.settings});
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con il salvataggio: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleValidSubmitEmails(event, values, edit = false) {
        let formResult = await setSettingsEmails(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Impostazioni salvate con successo", "success");
            this.setState({settings: formResult.settings});
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con il salvataggio: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async componentDidMount() {
        let settings = await getInfoSettings();

        console.log("settings")
        console.log(settings)
        if (typeof settings.error !== "undefined") {
            global.notify("Errore: " + settings.error, "danger");
            // history.back();
            return;
        }
        this.setState({settings: settings.settings});
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Impostazioni sistema"/>
                        <Card>
                            <CardBody>
                                <h5>Impostazione Commissione di servizio</h5>
                                {this.state.settings !== null ?
                                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                        this.handleValidSubmitServiceFee(e, v)
                                    }}>
                                        <div className={"row"}>
                                            <div className="form-group col-md-6">
                                                <AvField name="service_fee"
                                                         label="Prezzo generico per la commissione di servizio"
                                                         value={this.state.settings.service_fee}
                                                         className="form-control" placeholder="Prezzo" type="number"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="maximum_ticket_purchasable"
                                                         label="Numero massimo di ticket acquistabili"
                                                         value={this.state.settings.maximum_ticket_purchasable}
                                                         className="form-control" placeholder="Prezzo" type="number"/>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button type="submit" color="success">Salva</Button>
                                        </div>
                                    </AvForm> : <></>}
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <h5>Impostazione Commissione invio SMS</h5>
                                {this.state.settings !== null ?
                                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                        this.handleValidSubmitSendSMSFee(e, v)
                                    }}>
                                        <div className={"row"}>
                                            <div className="form-group col-md-12">
                                                <AvField name="send_sms_fee"
                                                         label="Prezzo per l'invio dei ticket via SMS"
                                                         value={this.state.settings.send_sms_fee}
                                                         className="form-control" placeholder="Prezzo" type="number"/>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button type="submit" color="success">Salva</Button>
                                        </div>
                                    </AvForm> : <></>}
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <h5>Impostazione Abbonamento Geoticket+</h5>
                                {this.state.settings !== null ?
                                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                        this.handleValidSubmitSubscription(e, v)
                                    }}>
                                        <div className={"row"}>
                                            <div className="form-group col-md-4">
                                                <AvField name="price_monthly"
                                                         label="Prezzo mensile dell'abbonamento di Geoticket+"
                                                         value={this.state.settings.price_subscription_monthly}
                                                         className="form-control" placeholder="Prezzo" type="number"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <AvField name="price_yearly"
                                                         label="Prezzo annuale dell'abbonamento di Geoticket+"
                                                         value={this.state.settings.price_subscription_yearly}
                                                         className="form-control" placeholder="Prezzo" type="number"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <AvField name="days_trial"
                                                         label="Giorni di prova per l'abbonamento"
                                                         value={this.state.settings.days_subscription_trial}
                                                         className="form-control" placeholder="Giorni di prova" type="number"/>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button type="submit" color="success">Salva</Button>
                                        </div>
                                    </AvForm> : <></>}
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <h5>Impostazioni per l'attivazione del premium</h5>
                                {this.state.settings !== null ?
                                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                        this.handleValidSubmitPremium(e, v)
                                    }}>
                                        <div className={"row"}>
                                            <div className="form-group col-md-4">
                                                <AvField name="mushroom_limit_picking"
                                                         label="Raccolte gratis per il segnafunghi"
                                                         value={this.state.settings.mushroom_limit_picking}
                                                         className="form-control" placeholder="Raccolte gratis" type="number"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <AvField name="mushroom_limit_course"
                                                         label="Percorsi attivabili gratis per il segnafunghi"
                                                         value={this.state.settings.mushroom_limit_course}
                                                         className="form-control" placeholder="Percorsi gratis" type="number"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <AvField name="number_level_free"
                                                         label="Numero di livelli crescita funghi gratis"
                                                         value={this.state.settings.number_level_free}
                                                         className="form-control" placeholder="Numero livelli" type="number"/>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button type="submit" color="success">Salva</Button>
                                        </div>
                                    </AvForm> : <></>}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Impostazioni email</h5>
                                {this.state.settings !== null ?
                                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                        this.handleValidSubmitEmails(e, v)
                                    }}>
                                        <div className={"row"}>
                                            <div className="form-group col-md-6">
                                                <AvField name="email_send_insurance"
                                                         label="Email notifica delle assicurazioni acquistati"
                                                         value={this.state.settings.email_send_insurance}
                                                         className="form-control" placeholder="Email" type="email"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="email_send_bought_together"
                                                         label="Email notifica dei pacchetti acquistati 'comprati insieme'"
                                                         value={this.state.settings.email_send_bought_together}
                                                         className="form-control" placeholder="Email" type="email"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="email_send_recommend"
                                                         label="Email notifica dei pacchetti acquistati 'raccomandati'"
                                                         value={this.state.settings.email_send_recommend}
                                                         className="form-control" placeholder="Email" type="email"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="email_send_products"
                                                         label="Email notifica dei prodotti acquistati"
                                                         value={this.state.settings.email_send_products}
                                                         className="form-control" placeholder="Email" type="email"/>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button type="submit" color="success">Salva</Button>
                                        </div>
                                    </AvForm> : <></>}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Impostazioni sistema di pagamento</h5>
                                {this.state.settings !== null ?
                                    <AvForm className="form-horizontal" onValidSubmit={(e, v,) => {
                                        this.handleValidSubmit(e, v)
                                    }}>
                                        <div className={"row"}>
                                            <div className="form-check col-md-12 mb-4">
                                                <AvField type="checkbox" className="form-check-input"
                                                         value={this.state.settings.stripe.is_enabled}
                                                         id="status_stripe" name="status_stripe"
                                                         label="Pagamento stripe abilitato"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="stripe_public" label="Chiave pubblica stripe"
                                                         value={this.state.settings.stripe.stripe_public}
                                                         className="form-control" placeholder="Chiave pubblica stripe"
                                                         type="text"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="stripe_secret" label="Chiave segreta stripe"
                                                         value={this.state.settings.stripe.stripe_secret}
                                                         className="form-control" placeholder="Chiave segreta stripe"
                                                         type="text"/>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className={"row"}>
                                            <div className="form-check col-md-12 mb-4">
                                                <AvField type="checkbox" className="form-check-input"
                                                         value={this.state.settings.paypal.is_enabled}
                                                         id="status_paypal" name="status_paypal"
                                                         label="Pagamento paypal abilitato"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="paypal_client" label="Chiave pubblica paypal"
                                                         value={this.state.settings.paypal.paypal_client}
                                                         className="form-control" placeholder="Chiave cliente paypal"
                                                         type="text"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <AvField name="paypal_secret" label="Chiave segreta paypal"
                                                         value={this.state.settings.paypal.paypal_secret}
                                                         className="form-control" placeholder="Chiave segreta paypal"
                                                         type="text"/>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button type="submit" color="success">Salva</Button>
                                        </div>
                                    </AvForm> : <></>}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(SettingsPage));
